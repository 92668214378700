
import { FormController, validationRules } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { CodeModule } from '@/modules/code/store'

import * as analytics from '../../helpers/analytics'
import { OrganizationModule } from '../../store'

@Component({
  components: {
    Dialog
  },
  name: 'OrganizationBitbucketServerTokenCreateDialog'
})
export default class extends Vue {
  codeModule = getModule(CodeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  formController = new FormController({
    initialModel: {
      serverUrl: '',
      token: '',
      username: ''
    },
    validationRules: {
      serverUrl: [
        ...validationRules.exists,
        ...validationRules.url
      ],
      token: validationRules.exists,
      username: validationRules.exists
    }
  })

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.$params.organizationId)!
  }

  get docsUrl () {
    return 'https://docs.icepanel.io'
  }

  created () {
    this.formController.submitHandler = async model => {
      const {
        serverUrl,
        username
      } = await this.organizationModule.bitbucketServerTokenCreate({
        create: {
          serverUrl: model.serverUrl,
          token: model.token,
          username: model.username
        },
        organizationId: this.currentOrganization.id
      })

      analytics.organizationBitbucketServerTokenCreate.track(this, {
        organizationBitbucketServerUrl: serverUrl,
        organizationBitbucketServerUsername: username,
        organizationId: [this.currentOrganization.id]
      })

      await this.codeModule.codeReposList(this.currentOrganization.id)

      await this.$replaceQuery({
        bitbucket_server_token_create: undefined,
        tab: 'bitbucket-server'
      })
    }
  }

  closed () {
    this.formController.resetModel()
    this.formController.resetStatus()
  }

  opened () {
    analytics.organizationBitbucketServerTokenCreateDialog.track(this, {
      organizationId: [this.currentOrganization.id]
    })
  }
}
