
import { CodeRepo, CodeRepoType } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import * as env from '@/helpers/env'
import { AlertModule } from '@/modules/alert/store'
import { CodeModule } from '@/modules/code/store'

import BitbucketServerTokenCreateDialog from '../components/bitbucket-server-token/create-dialog.vue'
import BitbucketServerTokenUpdateDialog from '../components/bitbucket-server-token/update-dialog.vue'
import * as analytics from '../helpers/analytics'
import { OrganizationModule } from '../store'

interface IntegrationType {
  click: () => void
  icon: string
  id: CodeRepoType
  text: string
}

interface IntegrationItem {
  codeRepos: CodeRepo[]
  edit: () => void
  id: string
  integrationType?: IntegrationType
  linkedAt: Date
  linkedBy: string
  linkedTo: string
  remove: () => void
}

@Component({
  components: {
    BitbucketServerTokenCreateDialog,
    BitbucketServerTokenUpdateDialog
  },
  name: 'OrganizationIntegrations'
})
export default class OrganizationIntegrations extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  codeModule = getModule(CodeModule, this.$store)
  organizationModule = getModule(OrganizationModule, this.$store)

  loading = true
  deleting = false
  searchTerm = ''
  deletingIntegrationId = ''

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.$params.organizationId)!
  }

  get integrationCount () {
    return this.githubInstalls.length + this.azureDevopsAuthorizations.length + this.gitlabAuthorizations.length + this.bitbucketAuthorizations.length + this.bitbucketServerTokens.length
  }

  get azureDevopsAuthorizations () {
    return this.organizationModule.azureDevopsAuthorizations.filter(o => o.organizationId === this.currentOrganization.id)
  }

  get bitbucketAuthorizations () {
    return this.organizationModule.bitbucketAuthorizations.filter(o => o.organizationId === this.currentOrganization.id)
  }

  get bitbucketServerTokens () {
    return this.organizationModule.bitbucketServerTokens.filter(o => o.organizationId === this.currentOrganization.id)
  }

  get githubInstalls () {
    return this.organizationModule.githubInstalls.filter(o => o.organizationId === this.currentOrganization.id)
  }

  get gitlabAuthorizations () {
    return this.organizationModule.gitlabAuthorizations.filter(o => o.organizationId === this.currentOrganization.id)
  }

  get headers () {
    return [
      {
        text: 'Integration',
        value: 'integrationType',
        width: '20%'
      },
      {
        text: 'Linked to',
        value: 'linkedTo',
        width: '15%'
      },
      {
        text: 'Linked by',
        value: 'linkedBy',
        width: '15%'
      },
      {
        text: 'Linked at',
        value: 'linkedAt',
        width: '15%'
      },
      {
        text: 'Linked',
        value: 'linked',
        width: '15%'
      },
      {
        align: 'end',
        text: '',
        value: 'actions',
        width: '20%'
      }
    ]
  }

  get items () {
    const items: IntegrationItem[] = []

    this.azureDevopsAuthorizations.forEach(o => {
      const user = this.organizationModule.organizationUsers[o.organizationId]?.[o.createdById]
      items.push({
        codeRepos: this.codeModule.codeRepos.filter(c => c.azureDevopsAuthorizationId === o.id).sort((a, b) => a.name.localeCompare(b.name)),
        edit: () => {
          analytics.organizationAzureDevopsAuthorizationEditLink.track(this, {
            organizationId: [this.currentOrganization.id]
          })

          window.open(`https://dev.azure.com/${o.accountName}/_usersSettings/authorizations`)?.focus()
        },
        id: o.id,
        integrationType: this.integrationTypes.find(i => i.id === 'azure-devops'),
        linkedAt: new Date(o.createdAt),
        linkedBy: user?.name || 'No user found',
        linkedTo: o.accountName || 'No organization selected',
        remove: async () => {
          try {
            this.deletingIntegrationId = o.id

            await this.organizationModule.azureDevopsAuthorizationDelete({
              azureDevopsAuthorizationId: o.id,
              organizationId: this.currentOrganization.id
            })

            analytics.organizationAzureDevopsAuthorizationDelete.track(this, {
              organizationId: [this.currentOrganization.id]
            })
          } finally {
            this.deletingIntegrationId = ''
          }
        }
      })
    })

    this.bitbucketAuthorizations.forEach(o => {
      const user = this.organizationModule.organizationUsers[o.organizationId]?.[o.createdById]
      items.push({
        codeRepos: this.codeModule.codeRepos.filter(c => c.bitbucketAuthorizationId === o.id).sort((a, b) => a.name.localeCompare(b.name)),
        edit: () => {
          analytics.organizationBitbucketAuthorizationEditLink.track(this, {
            organizationId: [this.currentOrganization.id]
          })

          window.open('https://bitbucket.org/account/settings/app-authorizations/')?.focus()
        },
        id: o.id,
        integrationType: this.integrationTypes.find(i => i.id === 'bitbucket'),
        linkedAt: new Date(o.createdAt),
        linkedBy: user?.name || 'No user found',
        linkedTo: o.workspaceName || 'No workspace selected',
        remove: async () => {
          try {
            this.deletingIntegrationId = o.id

            await this.organizationModule.bitbucketAuthorizationDelete({
              bitbucketAuthorizationId: o.id,
              organizationId: this.currentOrganization.id
            })

            analytics.organizationBitbucketAuthorizationDelete.track(this, {
              organizationId: [this.currentOrganization.id]
            })
          } finally {
            this.deletingIntegrationId = ''
          }
        }
      })
    })

    this.bitbucketServerTokens.forEach(o => {
      const user = this.organizationModule.organizationUsers[o.organizationId]?.[o.createdById]
      items.push({
        codeRepos: this.codeModule.codeRepos.filter(c => c.bitbucketServerTokenId === o.id).sort((a, b) => a.name.localeCompare(b.name)),
        edit: () => {
          this.$replaceQuery({
            bitbucket_server_token_update: o.id
          })
        },
        id: o.id,
        integrationType: this.integrationTypes.find(i => i.id === 'bitbucket-server'),
        linkedAt: new Date(o.createdAt),
        linkedBy: user?.name || 'No user found',
        linkedTo: o.username,
        remove: async () => {
          try {
            this.deletingIntegrationId = o.id

            const { serverUrl, username } = o

            await this.organizationModule.bitbucketServerTokenDelete({
              bitbucketServerTokenId: o.id,
              organizationId: this.currentOrganization.id
            })

            analytics.organizationBitbucketServerTokenDelete.track(this, {
              organizationBitbucketServerUrl: serverUrl,
              organizationBitbucketServerUsername: username,
              organizationId: [this.currentOrganization.id]
            })
          } finally {
            this.deletingIntegrationId = ''
          }
        }
      })
    })

    this.gitlabAuthorizations.forEach(o => {
      const user = this.organizationModule.organizationUsers[o.organizationId]?.[o.createdById]
      items.push({
        codeRepos: this.codeModule.codeRepos.filter(c => c.gitlabAuthorizationId === o.id).sort((a, b) => a.name.localeCompare(b.name)),
        edit: () => {
          analytics.organizationGitlabAuthorizationEditLink.track(this, {
            organizationId: [this.currentOrganization.id]
          })

          window.open('https://gitlab.com/-/profile/applications')?.focus()
        },
        id: o.id,
        integrationType: this.integrationTypes.find(i => i.id === 'gitlab'),
        linkedAt: new Date(o.createdAt),
        linkedBy: user?.name || 'No user found',
        linkedTo: o.groupName || 'No group selected',
        remove: async () => {
          try {
            this.deletingIntegrationId = o.id

            await this.organizationModule.gitlabAuthorizationDelete({
              gitlabAuthorizationId: o.id,
              organizationId: this.currentOrganization.id
            })

            analytics.organizationGitlabAuthorizationDelete.track(this, {
              organizationId: [this.currentOrganization.id]
            })
          } finally {
            this.deletingIntegrationId = ''
          }
        }
      })
    })

    this.githubInstalls.forEach(o => {
      const user = this.organizationModule.organizationUsers[o.organizationId]?.[o.createdById]
      items.push({
        codeRepos: this.codeModule.codeRepos.filter(o => o.type === 'github').sort((a, b) => a.name.localeCompare(b.name)),
        edit: () => {
          analytics.organizationGithubInstallEditLink.track(this, {
            organizationId: [this.currentOrganization.id]
          })

          if (o.ownerType === 'organization') {
            window.open(`https://github.com/organizations/${o.ownerLogin}/settings/installations/${o.githubId}`)?.focus()
          } else {
            window.open(`https://github.com/settings/installations/${o.githubId}`)?.focus()
          }
        },
        id: o.id,
        integrationType: this.integrationTypes.find(o => o.id === 'github'),
        linkedAt: new Date(o.createdAt),
        linkedBy: user?.name || 'No user found',
        linkedTo: o.ownerLogin || 'No organization selected',
        remove: async () => {
          try {
            this.deletingIntegrationId = o.id

            await this.organizationModule.githubInstallDelete({
              githubInstallId: o.id,
              organizationId: this.currentOrganization.id
            })

            analytics.organizationGithubInstallDelete.track(this, {
              organizationId: [this.currentOrganization.id]
            })
          } finally {
            this.deletingIntegrationId = ''
          }
        }
      })
    })

    return items
  }

  get integrationTypes () {
    const questionmarkIndex = this.$route.fullPath.indexOf('?')
    const fullPath = this.$route.fullPath.slice(0, questionmarkIndex > -1 ? questionmarkIndex : undefined)
    const state = encodeURIComponent(`${this.currentOrganization.id}_${fullPath}`)

    const integrationTypes: IntegrationType[] = [
      {
        click: () => {
          analytics.organizationAzureDevopsAuthorizationSetupLink.track(this, {
            organizationId: [this.currentOrganization.id]
          })

          window.location.href = `https://app.vssps.visualstudio.com/oauth2/authorize?client_id=${env.AZURE_DEVOPS_APP_ID}&response_type=Assertion&state=${state}&scope=vso.code%20vso.project&redirect_uri=${env.AZURE_DEVOPS_REDIRECT_URI}`
        },
        icon: '$fab-microsoft',
        id: 'azure-devops',
        text: 'Azure DevOps'
      },
      {
        click: () => {
          analytics.organizationBitbucketAuthorizationSetupLink.track(this, {
            organizationId: [this.currentOrganization.id]
          })

          window.location.href = `https://bitbucket.org/site/oauth2/authorize?client_id=${env.BITBUCKET_APP_ID}&response_type=code&state=${state}`
        },
        icon: '$fab-bitbucket',
        id: 'bitbucket',
        text: 'Bitbucket'
      },
      {
        click: () => this.$replaceQuery({
          bitbucket_server_token_create: '1'
        }),
        icon: '$fab-bitbucket',
        id: 'bitbucket-server',
        text: 'Bitbucket Server'
      },
      {
        click: () => {
          analytics.organizationGithubInstallSetupLink.track(this, {
            organizationId: [this.currentOrganization.id]
          })

          window.location.href = `https://github.com/apps/${env.GITHUB_APP_ID}/installations/new?state=${state}`
        },
        icon: '$fab-github',
        id: 'github',
        text: 'GitHub'
      },
      {
        click: () => {
          analytics.organizationGitlabAuthorizationSetupLink.track(this, {
            organizationId: [this.currentOrganization.id]
          })

          window.location.href = `https://gitlab.com/oauth/authorize?client_id=${env.GITLAB_APP_ID}&redirect_uri=${env.GITLAB_REDIRECT_URI}&response_type=code&state=${state}&scope=read_api+read_user+read_repository`
        },
        icon: '$fab-gitlab',
        id: 'gitlab',
        text: 'GitLab'
      }
    ]

    return integrationTypes
  }

  get codeRepoDisplay () {
    return (codeRepo: CodeRepo) => ({
      link: codeRepo.url,
      name: codeRepo.name.slice(codeRepo.name.lastIndexOf('/') + 1),
      prefix: codeRepo.name.slice(0, codeRepo.name.lastIndexOf('/') + 1).replace(/\//g, ' / ')
    })
  }

  async mounted () {
    analytics.organizationIntegrationsScreen.track(this, {
      organizationId: [this.currentOrganization.id]
    })

    try {
      await Promise.all([
        this.organizationModule.azureDevopsAuthorizationsList(this.currentOrganization.id),
        this.organizationModule.bitbucketAuthorizationsList(this.currentOrganization.id),
        this.organizationModule.bitbucketServerTokensList(this.currentOrganization.id),
        this.organizationModule.githubInstallsList(this.currentOrganization.id),
        this.organizationModule.gitlabAuthorizationsList(this.currentOrganization.id),
        this.codeModule.codeReposList(this.currentOrganization.id),
        this.organizationModule.organizationUsersList(this.currentOrganization.id)
      ])
    } catch (err: any) {
      this.alertModule.pushAlert({
        color: 'error',
        message: err.message
      })
    } finally {
      this.loading = false
    }
  }
}
