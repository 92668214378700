
import { FormController, validationRules } from '@icepanel/app-form'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'

import * as analytics from '../../helpers/analytics'
import { OrganizationModule } from '../../store'

@Component({
  components: {
    Dialog
  },
  name: 'OrganizationBitbucketServerTokenUpdateDialog'
})
export default class extends Vue {
  organizationModule = getModule(OrganizationModule, this.$store)

  formController = new FormController({
    initialModel: {
      serverUrl: '',
      token: '',
      username: ''
    },
    validationRules: {
      serverUrl: [
        ...validationRules.exists,
        ...validationRules.url
      ],
      token: validationRules.exists,
      username: validationRules.exists
    }
  })

  get bitbucketServerTokenId () {
    return this.$queryValue('bitbucket_server_token_update')
  }

  get currentOrganization () {
    return this.organizationModule.organizations.find(o => o.id === this.$params.organizationId)!
  }

  get bitbucketServerToken () {
    return this.organizationModule.bitbucketServerTokens.find(o => o.id === this.bitbucketServerTokenId)
  }

  get docsUrl () {
    return 'https://docs.icepanel.io'
  }

  created () {
    this.formController.submitHandler = async model => {
      if (!this.bitbucketServerTokenId) {
        throw new Error('Could not find bitbucket server token id')
      }

      await this.organizationModule.bitbucketServerTokenUpdate({
        bitbucketServerTokenId: this.bitbucketServerTokenId,
        organizationId: this.currentOrganization.id,
        update: {
          serverUrl: model.serverUrl,
          token: model.token,
          username: model.username
        }
      })

      analytics.organizationBitbucketServerTokenUpdate.track(this, {
        organizationBitbucketServerUrl: model.serverUrl,
        organizationBitbucketServerUsername: model.username,
        organizationId: [this.currentOrganization.id]
      })

      await this.$replaceQuery({
        bitbucket_server_token_update: undefined
      })
    }
  }

  open () {
    if (this.bitbucketServerToken) {
      this.formController.resetModel()
      this.formController.model.serverUrl = this.bitbucketServerToken.serverUrl
      this.formController.model.username = this.bitbucketServerToken.username
    }
  }

  opened () {
    analytics.organizationBitbucketServerTokenUpdateDialog.track(this, {
      organizationId: [this.currentOrganization.id]
    })
  }
}
